.footer {
  color: $navbar-text-muted;
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  padding: 0 12px;
  transition: all $action-transition-duration $action-transition-timing-function;
  -moz-transition: all $action-transition-duration $action-transition-timing-function;
  -webkit-transition: all $action-transition-duration $action-transition-timing-function;
  -ms-transition: all $action-transition-duration $action-transition-timing-function;
  font-size: calc(#{$default-font-size} - 0.05rem);
  font-family: $type1;

  a {
    color: $navbar-accent !important;
  }

  .footer-content {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    height: 43px;
  }

  a {
    color: theme-color(success);
    font-size: inherit;
  }

  @media (min-width: 992px) {
    left: 100px;
  }

  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;

    left: 0;
      font-size: 11px;
  }
}