/* Tooltips */

.tooltip-demo,
.popover-demo {
	.btn-toolbar {
		button {
			position: relative;
			display: inline-block;
			opacity: 1;
			margin: 0 10px 10px 0;
		}

		.bs-tooltip-bottom-demo,
		.bs-tooltip-top-demo {
			.arrow {
				left: 50%;
			}
		}

		.bs-tooltip-right-demo,
		.bs-tooltip-left-demo {
			.arrow {
				&:before {
					top: calc((100%-#{$tooltip-arrow-width})/2);
				}
			}
		}
	}
}

.tooltip {
	font-size: $tooltip-font-size;
	min-width: 5.625rem;
	z-index: 1029;
}

.bs-tooltip-left .arrow::before {
	border-left-color: #FFF !important;
}
.bs-tooltip-right .arrow::before {
	border-right-color: #FFF;
}
.bs-tooltip-bottom .arrow::before {
	border-bottom-color: #FFF;
}
.bs-tooltip-top .arrow::before {
	border-top-color: #FFF;
}

.tooltip-inner {
	max-width: 400px;
	padding: 0.4rem 0.75rem;
	color: #444;
	background-color: #FFF;
	border-radius: 0.375rem;
	box-shadow: 0px 2px 16px 0 #CCC;
	text-align: left;

	ul {
		margin: 0;
		li {
			font-size: 12px
		}
	}
}

@each $color, $value in $theme-colors {
	.tooltip-#{$color} {
		@include tooltip-variant($color);
	}
}