$primary: #00b38a;
$color-status-valid: '#05ffde';
$color-status-invalid-l1: '#ff8870';
$color-status-invalidl0: '#ff7070';
$color-status-unknown: '#888';

@media (max-width:767px) {
    .stats-card-row {
        margin-bottom: 20px;
        .card-body {
            padding: 20px;
            h2 {
                font-size: 20px;
            }
            h4 {
                font-size: 14px;
                margin-bottom: 10px !important;
            }
        }
        .grid-margin {
            margin-bottom: 20px;
        }
    }
}

.dashboard-cards {
    @media (min-width: 992px) {
        &>.col-md-6 {
            margin-bottom: 0px;
        }
    }

    @media (max-width: 991px) {
        .report {
            &>div {
                justify-content: center;
            }

            .list-validation-status {
                align-items: stretch;
                margin-top: 40px;
            }
        }
    }

    @media (min-width: 992px) and (max-width: 1440px) {
        .chart {
            max-width: 100%;
            flex: 1;
        }
        .legends {
            display: none;
        }
        .list-validation-status {
            display: none;
        }

        .list-validation-status.compact {
            display: flex;
        }
    }
}

.card-body {
    display: flex;
    flex-direction: column;

    h2 {
        font-size: 36px;
        margin: 0;
    }
}

.section-header {
    display: flex;

    .section-icon i {
        font-size: 32px;
        color: $primary;
        background-color: #00b38a33;
        padding: 8px 12px;
        border-radius: 8px;
        margin-right: 15px;
    }

    .section-content {
        .card-title {
            margin-bottom: 4px;
        }

        a {
            font-size: 12px;
            color: #0684eb;
            letter-spacing: 1px;
        }
    }
}

.validate-email-form {
    display: flex;
    flex-direction: column;
    height: 100px;

    .input-row {
        display: flex;

        input {
            border-radius: 4px 0 0 4px;
            border-color: #DDD;
            max-width: 450px;
        }

        button {
            border-radius: 0 4px 4px 0;
        }
    }

    .validation-status {
        height: 60px;
        margin: 10px 0 0 0;
        font-size: 14px;
        color: #666;

        span {
            margin-right: 5px;
        }
    }
}

button {
    &.btn-new-list {
        max-width: 320px;
    }
}

#email-validate-chart {
    ul {
        margin: 0;

        li {
            margin: 0;

            .legend-dots {
                width: 10px;
                height: 10px;
            }
        }
    }
}

.email-status-valid {
    color: #{$status-valid-text} !important;
    background: #{$status-valid-text-background} !important;
}

.email-status-unknown {
    color: #{$status-unknown-text} !important;
    background: #{$status-unknown-text-background} !important;
}

.email-status-invalid-l0 {
    color: #{$status-invalid-l0-text} !important;
    background: #{$status-invalid-l0-text-background} !important;
}

.email-status-invalid-l1 {
    color: #{$status-invalid-l1-text} !important;
    background: #{$status-invalid-l1-text-background} !important;
}

.list-stats-card {
    background: #F4F4F4;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 10px 0;
    margin-top: 15px;

    th {
        border-bottom: 1px solid #E0E0E0 !important;
    }
}

.search-field {
    width: 100%;
    max-width: 600px;
    margin-left: 20px;
    margin-right: 20px;

    .mdi::before {
        color: $navbar-fg;
    }
    .mdi-close {
        font-size: 18px;
        &::before {
            color: #444;
        }
    }

    .input-group {
        background: rgba(255, 255, 255, 0.3);
        border-radius: 6px;

        ::placeholder {
            color: $navbar-text-muted;
        }

        input {
            height: 36px;
            color: $navbar-fg;

        }

        button {
            display: none;
            margin-right: 0;
            border-radius: 6px;
            font-weight: 500;
            font-size: 14px;
            background: rgba(255, 255, 255, 0.15);
            color: $navbar-fg;
            margin: 5px;
            height: 26px;
            line-height: 16px;
            padding: 0 20px;
            transition: all .5s;
        }

        &:hover {
            button {
                display: block;
            }
        }
    }
}

.card .report {
    margin: 60px 0;
}

.list-validation-status,
.list-validation-status.compact {
    list-style: none;
    display: flex;
    flex-direction: column;

    .badge-stats {
        font-size: 13px;
        background: #888;
        display: inline-block;
        padding: 5px 15px;
        border-radius: 20px;
        color: #FFF;
        margin-bottom: 10px;
    }

    li {
        display: flex;
        align-items: center;
        padding: 6px 0;

        .legend-dots {
            width: 20px;
            height: 20px;
            display: inline-block;
            border-radius: 10px;
            margin-right: 20px;
        }

        .label,
        .value {
            font-size: 16px;
        }

        .label {
            display: flex;
            flex: 1;
        }

        .value {
            margin-right: 30px;
        }
    }
}

.list-validation-status.compact {
    margin-top: 20px;
    display: none;

    .badge-stats {
        margin-bottom: 10px;
    }
}

@media (min-width: 768px) {
    .list-validation-status {
        margin: 0;
        padding: 0;
        align-items: flex-start;
        justify-content: center;
    }
}

@media (max-width: 767px) {
    .list-validation-status {
        margin: 40px auto 0 auto;
    }
}