/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
  height: 100%;
}

body {
  background: $content-bg;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    min-height: 100vh;
    padding-top: 0;

    .main-panel {
      width: 100%;
      left: 0;
      transition: none;

      .content-wrapper {
        padding: 0;
        background: transparent;

        >div {
          height: 100%;
        }
      }
    }
  }
}

.brand-logo {
  .logo-image {
    img {
      max-width: 100%;
    }
  }

  .app-title {
    color: #f7c53a;
    font-family: 'Exo', sans-serif;
    font-weight: 600;
    font-size: 22px;
  }

  .app-slogan {
    font-size: 9px;
    color: #FFF;
    font-weight: 400;
    letter-spacing: 0.6px;
  }

  &.sm {
    .app-slogan {
      font-size: 10px !important;
    }

    .logo-image {
      width: 52px !important;
      margin-right: 15px;
    }
  }

  &.l {
    .logo-image {
      width: 52px !important;
      margin-right: 15px;
    }
  }
}

@media (min-width: 992px) {
  .page-body-wrapper {
    min-height: calc(100vh - #{$navbar-height});
    padding-top: $navbar-height;
  }

  .logo-image {
    width: 46px;
    margin-left: 12px;
    margin-right: 40px;
  }

  .logo-text {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .logo-image {
    width: 44px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .app-title {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .logo-image {
    width: 42px;
    margin-right: 15px;
    margin-top: 3px;
  }

  .app-title {
    font-size: 18px !important;
  }

  .app-slogan {
    font-size: 8px !important;
  }
}

@media (max-width: 767px) {
  .full-page-wrapper {
    .main-panel {
      top: 0 !important;
      bottom: 0 !important;
    }
  }

  .logo-image {
    width: 32px;
    margin-bottom: 2px;
  }

  .app-title {
    font-size: 0.9rem !important;
  }

  .app-slogan {
    font-size: 0.38rem !important;
  }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  margin: 8px;
  position: fixed;
  top: 60px;
  right: 0;
  left: 100px;
  bottom: 35px;
  border-radius: 5px;
  @include display-flex();
  @include flex-direction(column);

  @media (max-width: 991px) {
    margin-left: 0;
    left: 8px;
    width: calc(100% - 16px);
  }

  &.main-panel-only {
    transition: none;
  }
}

.content-wrapper {
  background: $content-bg;
  padding: 2.5rem 2.2rem;
  width: 100%;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  @include flex-grow(1);
}

.container-scroller {
  overflow: hidden;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: theme-color(danger);
  font-weight: $font-weight-light;
  font-size: $default-font-size;
  border-radius: 4px;
}

.page-header {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  margin: 0 0 1.5rem 0;

  .breadcrumb {
    border: 0;
    margin-bottom: 0;
  }
}

.page-title {
  color: $black;
  font-size: 1.125rem;
  margin-bottom: 0;

  .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);

    i {
      font-size: .9375rem;
      line-height: 36px;
    }
  }
}