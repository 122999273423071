.steps-progress {
    position: relative;
    padding-left: 20px;
    height: 40px;
    max-width: 640px;
    width: 100%;

    .progressbar-container {
        position: absolute;
        top: calc(50% - 2px);
        width: calc(100% - 40px);

        .progress {
            height: 4px;
            border-radius: 0;
            .progress-bar {
                background-color: #{$primary};
                &.fill-step1 {
                    width: 0 !important;
                }
                &.fill-step2 {
                    width: 50% !important;
                }
                &.fill-step3 {
                    width: 100% !important;
                }
            }
        }
    }
    
    .step-circle {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #DDD;
        position: absolute;
        top: 0;

        i {
            font-size: 24px;
            color: #FFF;       
        }

        &.active {
            border: 3px solid #{$primary};
        }
        &.finished {
            background: #{$primary};
        }

        &.step1 {
            left: 0;
        }

        &.step2 {
            left: calc(50% - 20px);
        }

        &.step3 {
            left: calc(100% - 40px);
        }
    }
}

.file-uploader {
    .file-upload-form {
        max-width: 640px;
    }
    
}

.bulk-emails-validation {
    .progress-container {
        max-width: 640px;

        .progress-bar {
            &.terminated {
                background: #{$red};
            }
        }

        .progress-status {
            display: flex;
        }
    }
}

.image-csv {
    width: 240px;
    max-width: 100%;
}

.list-stat {
    margin-bottom: 10px;
    span {
        background: #666;
        padding: 4px 15px;
        border-radius: 15px;
        font-size: 14px;
        color: #FFF;
        margin-bottom: 10px;
    }
}