.report-summary {
    .list-validation-status {
        list-style: none;
        display: flex;
        flex-direction: column;

        .badge-stats {
            font-size: 14px;
            background: #888;
            display: inline-block;
            padding: 6px 15px;
            border-radius: 20px;
            color: #FFF;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        li {
            display: flex;
            align-items: center;
            padding: 6px 0;

            

            .legend-dots {
                width: 20px;
                height: 20px;
                display: inline-block;
                border-radius: 10px;
                margin-right: 20px;
            }

            .label, .value {
                font-size: 16px;
            }

            .label {
                display: flex;
                flex: 1;
            }
            .value {
                margin-right: 30px;
            }
        }
    }
    .chart-container {
        display: flex;
        height: 100%;
        align-items: flex-end;
        justify-content: center;
    }
}

.stats-card {
    td {
        &.action-buttons {
            padding: 0;
            text-align: center;
        }
        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            background: transparent;
            border: 0;
            color: #333;
            i {
                font-size: 20px;
            }
            &:hover {
                background: #00b38a;
                i {
                    color: #FFF;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .list-validation-status {
        margin: 0;
        padding: 0;
        align-items: flex-start;
        justify-content: center;
    }
}

@media (max-width: 767px) {
    .list-validation-status {
        margin: 40px auto 0 auto;
    }
}