.profile-page {
    display: flex;
    flex-direction: column;

    form.profile {
        max-width: 800px;

        .PhoneInputInput {
            border: 0;

            &::placeholder {
                color: #C0C0C0;
            }
        }

        select {
            option {
                color: #333 !important;
            }
        }

        button {
            width: 180px;
        }
    }

    form.change-password {
        max-width: 800px;
    }

    .btn-logout {
        align-self: center;
        background: transparent;
        border: none;
        color: #666;
        padding: 0;
        font-size: 14px;
        font-weight: normal;

        &:hover {
            color: #444;
        }
    }
}