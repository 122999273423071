.form-wrapper {
    background: #FFF;
    border-radius: 8px;
    margin: auto;
    margin-bottom: 70px;
    margin-top: 50px;

    h2 {
        font-weight: 500;
        font-size: 26px;
    }
}

.login-form {
    a {
        font-size: 0.875rem;
    }
}

.signup-form {
    margin-bottom: 0;

    a {
        color: #{$primary};
    }

    .PhoneInputInput {
        border: 0;

        &::placeholder {
            color: #C0C0C0;
        }
    }

    select {
        option {
            color: #333 !important;
        }
    }

    .btn-signup {
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner {
            display: inline-block;
            margin: 0 10px 0 0;
            border: 2px solid rgba(255, 255, 255, 0.3);
            border-top: 2px solid #FFF;
            width: 16px;
            height: 16px;
        }
    }

    .icon-info {
        position: absolute;
        top: 0;
        right: 8px;
        bottom: 0;
        align-items: center;
        display: flex;
        color: #AAA;
        cursor: pointer;
    }
}

.verify-email-form {
    h2 {
        font-weight: 500;
        font-size: 26px;
    }

    p {
        font-size: 13px;
        color: #555;
    }

    a {
        font-size: 15px;
    }
}

.input-box {
    position: relative;

    .btn-link,
    span {
        position: absolute;
        top: 0;
        right: 5px;
        bottom: 0;
        background: transparent;
        border: 0;
        font-size: 11px;
    }

    .btn-link {
        display: block;
    }

    span {
        display: flex;
        align-items: center;
        color: #888;
        margin-right: 5px;
    }
}

@media (min-width: 768px) {
    .form-wrapper {
        width: 460px;
    }
}

@media (max-width: 767px) {
    .auth {
        width: 100%;
    }
    .form-wrapper {
        max-width: 480px;
        width: 90%;
        margin-top: 40px;
    }
    .form-group {
        margin-bottom: 12px;
        .row > div:first-child {
            margin-bottom: 12px;
        }
    }
    .pr-2 {
        padding-right: 0 !important;
    }
    .pl-2 {
        padding-left: 0 !important;
    }

}