.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    background-color: rgba(40, 44, 49, 0.4);
    padding: 0;
    z-index: 999;
    animation: fadeIn 0.4s 1;
    overflow-y: auto;
}

.modal-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: calc(100% - 16px);
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
    max-width: 600px;
    animation: fadeInDown 0.4s ease-in-out;
    transition: all .4s ease-in-out;

    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        margin: 15px;
        padding: 8px;
        border-radius: 4px;
        cursor: pointer;
        z-index: 9;
        transition: .4s ease;
        &:hover {
            background: #E0E0E0;
        }
    }

    .modal-header {
        border: 0;
        padding: 20px 0;
        margin-bottom: 20px;
        border-bottom: 1px solid $primary;
        width: 100%;
        justify-content: center;

        .modal-title {
            margin-top: 10px;
        }
    }

    .modal-loader-wrapper {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
    }

    .modal-body {
        display: flex;
        justify-content: center;
        padding: 0 20px 20px 20px;
    }
}

.modal-email-status {
    min-height: 320px;
    .modal-email-status-content {
        display: flex;
        padding: 0 20px;
    
        .col-md-4, .col-md-8, .col-md-12 {
            padding: 0 10px;
        }
    
        .mdi-close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 15px;
            font-size: 20px;
            cursor: pointer;
        }
    
        .title-email-address {
            font-weight: 500;
            background: #00b38a2b;
            color: #00b38a;
            padding: 8px 15px;
            border-radius: 4px;
            font-size: 15px;
            margin: 20px auto 25px auto;
            text-align: center;
        }
    
        .email-status-info {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
    
            .email-status-info-label {
                font-size: 10px;
                text-transform: uppercase;
                color: #666;
                text-align: left;
                letter-spacing: 1px;
            }
    
            .email-status-info-value {
                margin-top: 8px;
                background: #F8F8F8;
                border: 1px solid #EEE;
                padding: 4px 10px;
                text-align: left;
                border-radius: 4px;
                font-size: 14px;
                min-height: 26px;
            }
        }
    }
}