.sidebar {
  display: flex;
  flex-direction: column;
  // min-height: calc(100vh - #{$navbar-height});
  padding: 18px 0 0 0;
  width: $sidebar-width;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;

  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;

    .nav-item {
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;

      .nav-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;
        color: $navbar-fg;
        margin-bottom: 12px;
        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;
        cursor: pointer;

        i {
          color: $navbar-fg;

          &.menu-icon {
            width: 36px;
            height: 36px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.3rem;
            line-height: 1;
            color: $navbar-fg;
            @include transition-duration(0.4s);
            transition-property: all;
            -webkit-transition-property: all;
          }
        }

        .menu-title {
          color: $navbar-fg;
          display: inline-block;
          font-size: 12px;
          line-height: 1;
          margin-top: 10px;
        }
      }

      &.active, &:hover {
        .nav-link {
          .menu-title {
            font-weight: $font-weight-medium;
          }

          i {
            &.menu-icon {
              background: rgba(255, 255, 255, 0.25);
              transform: scale(1.2);
            }
          }
        }
      }

      &.nav-profile {
        .nav-link {
          height: auto;
          line-height: 1;
          border-top: 0;
          padding: 1.25rem 0;

          .nav-profile-image {
            width: 44px;
            height: 44px;

            img {
              width: 44px;
              height: 44px;
              border-radius: 100%;
            }
          }

          .nav-profile-text {
            display: flex;
            flex-direction: column;
            margin-left: 1rem;

            .rtl & {
              margin-left: auto;
              margin-right: 1rem;
            }
          }

          .nav-profile-badge {
            font-size: 1.125rem;
            margin-left: auto;

            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }

      &.sidebar-actions {
        margin-top: 1rem;

        .nav-link {
          border-top: 0;
          display: block;
          height: auto;
        }

        &:hover {
          background: initial;

          .nav-link {
            color: initial;
          }
        }
      }
    }

    .spacer {
      flex: 1
    }
  }

  .nav-footer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }

  @media (min-width: 992px) {
    .nav-footer {
      display: none;
    }
  }
}


/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  // .sidebar {
  //   min-height: calc(100vh - 127px);
  // }
  .sidebar-offcanvas {
    background: linear-gradient($primary, $primary-dark);
    position: fixed;
    // max-height: calc(100vh - 127px);
    top: 80px;
    bottom: 54px;
    overflow: auto;
    right: -$sidebar-width-lg;
    border-radius: 8px;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;

    &.active {
      right: 16px;
    }
  }
}

@media screen and (max-width: 767px) { 
  .sidebar-offcanvas {
    top: 108px;
    bottom: 52px;
  }
}