.fw-400 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.ct-toast {
    padding: 25px !important;

    .ct-text {
        font-size: 15px;
        color: #333;
    }
}

.section-title {
    font-size: 18px;
    margin: 40px 0;

    &.border-top {
        border-top: 1px solid #DDD;
    }
}

.card {
    .section-title.border-top {
        margin: 0 -40px;
        padding: 40px 40px 20px 40px;
    }
}

.table-emails-list {

    th,
    td {
        padding: 0.9375rem 0;
    }
}

.progress-indetermined {
    animation-direction: reverse !important;
    background-color: #d8d8d8;
    transform: 0s !important;
}

.stats-card {
    margin-top: 60px;
    background: #FFF;
    padding: 0;
    border: 1px solid #E0E0E0;
    border-radius: 8px;

    th {
        border-bottom: 1px solid #E0E0E0;
    }

    table {
        th {
            background: #F4F4F4;
            border-bottom: 1px solid #E0E0E0;
        }

        th,
        td {
            border-right: 1px solid #E4E4E4;
            border-bottom: 1px solid #E4E4E4;

            &:last-child {
                border-right: none;
            }
        }

        tr {
            &:last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
    }
}


/*~~~~~~~~~~ Spinner ~~~~~~~~~~~~~~~*/

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(720deg);
    }
}

.spinner {
    border: 5px solid transparent;
    border-top: 5px solid #{$primary};
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation-name: spin;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}


/*~~~~~~~~~~~~~~~~~ Sweet Alert ~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.swal2-modal {
    padding-bottom: 20px !important;

    .swal2-title {
        font-size: 1.5em !important;
        font-weight: normal !important;
    }

    .swal2-content {
        padding: 10px 0 !important;
        font-size: 1em !important;
        line-height: 1.5em !important;
    }

    .swal2-actions {
        button {
            padding: 10px 30px !important;
        }
    }
}